.header {
  font-size: 68px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.neon_text {
  letter-spacing: 3px;
  color: var(--neon-text-color);
  text-shadow: var(--neon-text-shadow);
  background: linear-gradient(180deg, #021c2344 0%, #003441 100%) !important;
  width: 100%;
  height: 100%;
  text-align: center;
  animation: neon_light 0.7s linear;
  animation-delay: 0.5s;
  padding-top: 100px;
  user-select: none;
}

.neon_title,
.neon_discr {
  text-align: center;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.neon_discr {
  font-size: 36px;
  transform: translateY(-37px);
}

@keyframes neon_light {
  0% {
    text-shadow: none;
  }
  40% {
    text-shadow: var(--neon-text-shadow);
  }
  50% {
    text-shadow: none;
  }
  70% {
    text-shadow: var(--neon-text-shadow);
  }
  80% {
    text-shadow: none;
  }
  90% {
    text-shadow: var(--neon-text-shadow);
  }
  95% {
    text-shadow: none;
  }
  100% {
    text-shadow: var(--neon-text-shadow);
  }
}

@media (max-width: 400px) {
  .header {
    font-size: 48px;
  }
}
