.footer {
  width: 100%;
  height: 200px;
  background-color: rgb(88, 88, 88);
  overflow: hidden;
  text-shadow: 0 0 1px #000;
  position: relative;
  color: #649b99;

  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px auto;
    position: relative;
  }
  &_background {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &_wrapper {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #021c23 0%, #003441 100%);
  }
  &_box {
    width: 200px;
    margin: 5px 15px;
    text-align: center;
    & p {
      user-select: none !important;
    }
    & h3 {
      user-select: none !important;
    }
    &_links {
      display: flex;
      justify-content: space-between;
      width: 510px;
      align-items: center;
    }
    &_link {
      text-align: center;
      overflow: visible;
      &_img {
        height: 50px;
        width: 50px;
      }
    }

    &_time {
      @extend .footer_info_title;
      font-size: 22px;
      line-height: 18px;
      & span {
        font-size: 14px;
      }
    }
    &_link {
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &_text {
        font-size: 11px;
        width: 105px;
        color: #649b99;
        text-shadow: 0 0 2px #000;
      }
      &_img {
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  &_info {
    display: flex;
    justify-content: space-between;

    &_address {
      padding-left: 5px;
      width: 200px;
    }
    &_title {
      text-align: center;
    }
  }
}

@media (max-width: 990px) {
  .footer {
    &_container {
      justify-content: center;
    }
  }
}
@media (max-width: 885px) {
  .footer {
    &_container {
      flex-direction: column;

      margin-top: 40px;
    }
  }
}

@media (max-width: 510px) {
  .footer {
    &_box {
      width: 165px;
      &_time {
        font-size: 18px;
        line-height: 16px;
        & span {
          font-size: 12px;
        }
      }
      &_link {
        &_img {
          width: 40px;
          height: 40px;
        }
        &_text {
          font-size: 10px;
          width: 75px;
        }
      }
      &_links {
        width: 360px;
        justify-content: center;
      }
    }
    &_info {
      width: 370px;
      &_address {
        text-align: left;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 380px) {
  .footer {
    &_container {
      height: 210px;
      padding-bottom: 15px;
    }
    &_box {
      margin: 5px 5px;
      height: 130px;
      line-height: 1rem;
      &_links {
        width: 300px;

        justify-content: space-between;
      }
      &_link {
        &_text {
          display: none;
        }
      }
      &_title {
        font-size: 16px;
      }
    }
    &_info {
      width: 310px;
      &_title {
        font-size: 16px;
      }
      &_address {
        font-size: 12px;
        padding-left: 15px;
      }
    }
  }
}
