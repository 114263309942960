.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
  user-select: none;
  margin-right: 10px;
  color: #649b99;
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(0deg, #021c23 0%, #003441 100%) !important;
    align-items: center;
    margin: 0 auto;
    padding: 0 30px;
  }
  &__button {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    width: 192px;
    height: 35px;
    border: 1px solid rgb(0, 0, 0);

    background: linear-gradient(
      313deg,
      rgb(0, 77, 71) 0%,
      rgb(82, 149, 139) 100%
    );

    border-radius: 5px;

    cursor: pointer;

    transition: text-shadow 0.3s ease;
    box-shadow: 0 1px 2px rgb(0, 0, 0);

    text-shadow: 1px 1px 1px rgb(0, 77, 71);
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 3px 2px rgb(0, 0, 0);
    }
  }
}

@media (max-width: 430px) {
  .title__button {
    height: 57px;
    width: 140px;
  }
}
