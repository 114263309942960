@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Roboto:wght@100;300;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

//Card
.card {
  border-radius: 7px;
  width: 360px;
  height: 490px;
  margin: 20px auto;
  overflow: hidden;
  position: relative;
  box-shadow: 0 6px 10px #000;
  color: #649b99;

  &__image {
    overflow: hidden;
    width: 360px;
    height: 360px;
    cursor: pointer;

    &_img {
      transition: transform 0.3s ease;
      &.active {
        transform: scale(1.3);
      }
    }
  }
  &__info {
    padding: 10px 15px 0;
    width: 360px;
    position: absolute !important;
    bottom: 0 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(325deg, #021c23 0%, #003441 100%);

    margin-top: -8px;
    transition: all 0.7s ease;

    &_active {
      @extend .card__info;
      border-radius: 10px;
      height: 440px;
    }

    & p {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-style: normal;
      margin: 10px 0 3px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      user-select: none;
      &:first-child {
        cursor: pointer;
      }
      &.full {
        text-overflow: initial;
        white-space: initial;
      }

      &:first-child {
        margin-top: 10px;
      }
    }
    & span {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
    }
  }
  &__image_img {
    width: 100%;
  }
}
.buy__button {
  transition: all 600ms ease;
}

.shareLink {
  background: transparent;
  cursor: pointer;
  margin: 0 6px;
  border: none;
}

.order {
  &_title {
    margin-block: 5px;
  }
  &_cancel {
    @extend .buy__button;
    margin-left: 55px;

    border: 1px solid rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    transform: translateY(14px);
    box-shadow: 0 1px 2px rgb(0, 0, 0);
  }
  &_phone_number {
    height: 30px;
    width: 155px;
    border-bottom: 1px solid #649b99;
    font-size: 18px;
    font-weight: bold;
    border-radius: 3px;
    color: #649b99;

    &_error {
      @extend .order_phone_number;
      box-shadow: 0 2px 1px #649b99;
      color: #649b99;
      border-color: #649b99;
    }
  }
  &_contactType {
    display: flex;
    align-items: center;
    height: 15px;
    width: 120px;
    cursor: pointer;
    &_img {
      width: 35px;
      height: 35px;
      background: transparent;
      margin-right: 5px;
    }
    &_wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 5px;
      padding-left: 15px;
      height: 35px;
    }
    &_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &_delivery {
    margin-right: 7px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;

    &_info {
      height: 80px;
      padding-top: 10px;
      font-family: "Montserrat", sans-serif;
    }

    &_address {
      margin: 0 10px;
      border-bottom: 1px solid #000;
      width: 250px;
      font-size: 16px;
      color: #649b99;
    }
    &_phone {
      color: #649b99;
      @extend .order_delivery_address;
      transform: translateY(20px);
    }
  }
}

@media (max-width: 390px) {
  .buy__button {
    font-size: 10px;
  }

  .card {
    width: 320px;
    height: 450px;

    &__image {
      width: 320px;
      height: 320px;
    }
    &__info {
      width: 320px;
      &_active {
        height: 400px;
        width: 320px;
      }
    }
  }

  .order {
    &_contactType {
      width: 100px;
      &_img {
        width: 20px;
        height: 20px;
      }
      &_wrapper {
        padding-left: 49px;
      }
    }

    &_cancel {
      margin-left: 45px;
      transform: translateY(-2px);
    }
  }
}
