@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Roboto:wght@100;300;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
}

:root {
  --neon-shadow-green: rgb(0, 255, 170);
  --neon-shadow-white: #fff;
  --neon-text-color: #fff;
  --neon-text-shadow: 0 0 7px var(--neon-shadow-white),
    0 0 10px var(--neon-shadow-white), 0 0 21px var(--neon-shadow-white),
    0 0 42px var(--neon-shadow-green), 0 0 82px var(--neon-shadow-green),
    0 0 92px var(--neon-shadow-green), 0 0 102px var(--neon-shadow-green),
    0 0 151px var(--neon-shadow-green);
  --main-light: var(rgb(185, 196, 201));
  --second-light: var(rgb(82, 149, 139));
  --main-dark: var(rgb(0, 77, 71));
  --second-dark: var(rgb(18, 130, 119));
  --main-light: #021c23;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-light) !important;
  overflow-x: hidden !important;
}

input {
  outline: none;
  border: none;
  background: transparent;
}

a {
  color: #649b99;
  text-decoration: none !important;
}
